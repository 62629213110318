import * as React from 'react';
import Title from 'antd/lib/typography/Title';
import {
    Col,
    Form,
    Row,
    Button
} from 'antd';
import IpopInput from 'src/shared/form-controls/IpopInput';
import { FormComponentProps, FormCreateOption } from 'antd/lib/form';

const FormItem = Form.Item;

interface ForgotFormProps extends FormComponentProps {
    username?: string;
    onSubmit?: () => void;
}

interface LoginFormState {

}

const formCreateOptions: FormCreateOption<ForgotFormProps> = {
    onFieldsChange(props: ForgotFormProps, fields: any[], allFields: any) {
        // pass
    }
};

class ForgotForm extends React.Component<ForgotFormProps & FormComponentProps, LoginFormState> {
    constructor(props: ForgotFormProps & FormComponentProps) {
        super(props);

        this.state = {

        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <Row type="flex" justify="center">
                    <Col>
                        <Title level={3}>Password Reset</Title>
                        <p>
                            Enter your email address below and an email will be sent to you with a link that
                            will allow you to change your password to a new one.
                            You may also contact an administrator at <a href={'mailto:' + $helpEmail}>{$helpEmail}</a> for further assistance.
                        </p>
                    </Col>
                </Row>
                <Form layout="horizontal" className="form-flex label-140">
                    <FormItem  label="Email">
                        {getFieldDecorator('username', {
                            initialValue: this.props.username,
                            rules: [{ required: true, message: 'Required.' }]
                        })(<IpopInput autoFocus={true} />)}
                    </FormItem>
                    <FormItem label="&nbsp;" colon={false}>
                        <Button onClick={this.props.onSubmit} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </FormItem>
                </Form>
            </React.Fragment>
        );
    }
}

export default Form.create<ForgotFormProps>(formCreateOptions)(ForgotForm);