import * as React from 'react';
import * as ReactDOM from 'react-dom';
import addGlobals from 'src/utils/Globals';
import History from '../utils/HistoryUtil';
import Login from './Login';
import registerServiceWorker from '../registerServiceWorker';
import { Route, Router } from 'react-router-dom';
import { store } from 'src/redux/Store';
import { Provider } from 'react-redux';
// @ts-ignore
import { ThroughProvider } from 'react-through';

addGlobals();

ReactDOM.render(
    <Provider store={store}>
        <ThroughProvider>
            <Router history={History}>
                <Route render={(props) => (<Login />)} />
            </Router>
        </ThroughProvider>
    </Provider>
    ,
    document.getElementById('root') as HTMLElement
);
registerServiceWorker();
