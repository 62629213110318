import * as queryString from 'query-string';
import * as React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import DevLoginForm from './DevLoginForm';
import FacilityLookupDTO from 'src/models/FacilityLookupDTO';
import ForgotForm from './ForgotForm';
import LoginDTO from 'src/models/LoginDTO';
import LoginForm from './LoginForm';
import LoginResponseDTO from 'src/models/LoginResponseDTO';
import LoginService from '../api/LoginApiService';
import LoginStep from 'src/consts/LoginStep';
import MfaChallengeForm from './MfaChallengeForm';
import MfaSetup from './Mfa/MfaSetup';
import PickFacility from './PickFacility';
import Title from 'antd/lib/typography/Title';
import { FormComponentProps } from 'antd/lib/form/Form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import '../app/App.less';
import '../shared/index.less';
import { Alert, Button, Row, Col, Spin, notification, Divider } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import HistoryUtil from 'src/utils/HistoryUtil';
import Routes from 'src/consts/Routes';

const LAST_LOGIN_USER_KEY = 'LAST_LOGIN_USER';
const CLIENT_ID = 'A9F384E7-77B3-4E16-9F31-7C40DA1A189C';

interface LoginProps {
  PasswordResetKey?: string;
  PassRequestExpired?: string;
  ReturnUrl?: string;
  Username?: string;
}

interface LoginState {
  key?: string;
  error: any;
  username: string | null;
  password: string | null;
  loading: boolean;
  process: LoginStep;
  loginResponse: LoginResponseDTO | null;
  facilities: FacilityLookupDTO[];
  selectedFacilityId: string | null;
}

class Login extends React.Component<LoginProps & RouteComponentProps<{}>, LoginState> {
  private _loginFormRef: React.Component<FormComponentProps>;
  private _devLoginFormRef: React.Component<FormComponentProps>;
  private _mfaChallengeFormRef: React.Component<FormComponentProps>;
  private _forgotFormRef: React.Component<FormComponentProps>;
  private _changePasswordFormRef: React.Component<FormComponentProps>;

  constructor(props: LoginProps & RouteComponentProps<{}>) {
    super(props);

    let deepLinkState = queryString.parse(props.location.search) as LoginProps;

    let lastUserName = deepLinkState.Username || localStorage.getItem(LAST_LOGIN_USER_KEY);

    let resetKeyExpired = deepLinkState.PassRequestExpired === 'True';

    let clearUrl = deepLinkState.ReturnUrl != null;

    this.state = {
      key: deepLinkState.PasswordResetKey,
      error:
        resetKeyExpired ?
          (
            {
              message: 'The link from the email has expired. Please request a new reset email and try again.',
            }
          )
          : null,
      username: lastUserName,
      password: null,
      loading: deepLinkState.PasswordResetKey == null ? false : true,
      process: LoginStep.LOGIN,
      loginResponse: null,
      facilities: [],
      selectedFacilityId: null
    };

    if (resetKeyExpired || clearUrl) {
      HistoryUtil.replace(Routes.BASE_ROUTE);
    }
  }

  componentDidMount() {
    if (this.state.key) {
      this.setState({ process: LoginStep.CHANGE_PASSWORD, loading: false });
    }
  }

  render() {
    let alert: JSX.Element | null = null;
    if (this.state.error !== null) {
      let message = 'An error occurred while trying to log you in.';
      if (this.state.error.errorDetails) {
        message = this.state.error.errorDetails;
      }
      alert = (
        <Alert
          message="Error"
          description={message}
          type="error"
          showIcon={true}
        />
      );
    }

    let content = null;
    switch (this.state.process) {
      case LoginStep.LOGIN:
        content = this.renderLogin();
        break;
      case LoginStep.FORGOT:
        content = this.renderForgot();
        break;
      case LoginStep.CHALLENGE:
        content = this.renderChallenge();
        break;
      case LoginStep.SETUP_MFA:
        content = this.renderMfaSetup();
        break;
      case LoginStep.ABOUT_TO_EXPIRE:
        content = this.renderAboutToExpire();
        break;
      case LoginStep.CHANGE_PASSWORD:
        content = this.renderPasswordChange();
        break;
      case LoginStep.SELECT_FACILITY:
        content = this.renderFacilitySelect();
        break;
      default:
        content = null;
    }

    return (
      <Spin spinning={this.state.loading}>
        {$siteUnderMaintenance ? (
          <Alert banner={true} type="error" message="The Site Is Currently Under Maintenance" />
        ) : null}
        <Row type="flex" justify="center">
          <Col lg={10} sm={18} xs={22}>
            <br />
            <br />
            <Row type="flex" justify="center">
              <Col>
                <img
                  className="logo"
                  src={'/template/images/logo_' + $productSku + '.png'}
                  alt="logo"
                />
              </Col>
            </Row>

            {$allowLogin && (
              <>
                <Divider />
                {alert}
                <Row type="flex" justify="center">
                  <Col sm={22} xs={24}>
                    {content}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>

        <div className="loginFooter">
          <div className="copyright-info">
            &copy; {new Date().getFullYear()} Iowa Hospital Association All Rights Reserved&nbsp;&nbsp;|&nbsp;&nbsp;Release {$appVersion}
          </div>
          <img
            className="tagline"
            src={'/template/images/tagline_' + $productSku + '.png'}
            alt="tagline"
          />
        </div>
      </Spin>
    );
  }

  renderLogin = () => {
    return (
      <React.Fragment>
        <br />
        <Title level={3} className="center-content">Login</Title>
        <p className="center-content">
          Please enter your email and password. If you don't know your email or
          password, please contact an administrator at{' '}
          <a href={'mailto:' + $helpEmail}>{$helpEmail}</a>
        </p>
        <br />
        <LoginForm
          userName={this.state.username}
          wrappedComponentRef={(r: any) => (this._loginFormRef = r)}
          onSubmit={this.submitLogin}
          onChange={e => {
            if (e && e.username && e.username.value) {
              this.setState({ username: e.username.value });
            }
          }}
        />
        <FormItem
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 19, offset: 5 }
          }}
        >
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => this.setState({ process: LoginStep.FORGOT })}
          >
            I forgot my password
          </Button>
        </FormItem>

        {/* Dead code eliminator prevents this code from being included in any deployed versions */}
        {DEBUG ? (
          <DevLoginForm
            wrappedComponentRef={(r: any) => (this._devLoginFormRef = r)}
            onSubmit={this.submitDevLogin}
          />
        ) : null}
      </React.Fragment>
    );
  }

  renderForgot = () => {
    return (
      <React.Fragment>
        <ForgotForm
          wrappedComponentRef={(r: any) => (this._forgotFormRef = r)}
          username={this.state.username || undefined}
          onSubmit={this.submitForgot}
        />
        <br />
        {this.returnToLogin()}
      </React.Fragment>
    );
  }

  renderChallenge = () => {
    return (
      <React.Fragment>
        <MfaChallengeForm
          wrappedComponentRef={(r: any) => (this._mfaChallengeFormRef = r)}
          onSubmit={this.submitMfaChallenge}
          submitText="Submit"
        />
        <br />
        {this.returnToLogin()}
      </React.Fragment>
    );
  }

  renderMfaSetup = () => {
    let authApps = this.state.loginResponse
      ? this.state.loginResponse.authApps || []
      : [];
    let qrUri = this.state.loginResponse
      ? this.state.loginResponse.mfaQrUri || ''
      : '';
    let deviceKey = this.state.loginResponse
      ? this.state.loginResponse.mfaDeviceKey || ''
      : '';
    let sessionData = this.state.loginResponse
      ? this.state.loginResponse.sessionData || ''
      : '';
    return (
      <React.Fragment>
        <MfaSetup
          onCancel={() => this.setState({ process: LoginStep.LOGIN })}
          cancelText={'Return to login'}
          authApps={authApps}
          qrUri={qrUri}
          deviceKey={deviceKey}
          sessionData={sessionData}
          onAdded={ res => {
            if (res !== null) {
              if (res.facilities?.length === 1) {
                let facility = res.facilities[0];
                this.setState({ loginResponse: res }, () => this.selectFacility(facility.id || ''));
              } else {
                this.setState({ process: LoginStep.SELECT_FACILITY, facilities: res.facilities || [], loginResponse: res });
              }
            }
          }}
        />
      </React.Fragment>
    );
  }

  renderAboutToExpire = () => {
    let daysTillExpire = this.state.loginResponse
      ? this.state.loginResponse.daysTillExpire || 0
      : 0;
    let msg = `You password will expire in ${daysTillExpire} day${
      daysTillExpire > 1 ? 's' : ''
      }.`;
    msg += ' Do you want to change it now?';

    return (
      <React.Fragment>
        <Title level={3}>Password Expires Soon</Title>
        <p>{msg}</p>

        <Row type="flex" gutter={8}>
          <Col>
            <Button
              onClick={() => this.aboutToExpireResponse(true)}
              type="primary"
            >
              Yes
            </Button>
          </Col>
          <Col>
            <Button onClick={() => this.aboutToExpireResponse(false)}>
              No
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderPasswordChange = () => {    
    return (
      <React.Fragment>
        <Title level={3}>Change Password</Title>
        {this.state.loginResponse && this.state.loginResponse.message ? (
          <p>{this.state.loginResponse.message}</p>
        ) : null}
        <p>Please enter a new password below.</p>
        <ChangePasswordForm
          username={this.state.key ? this.state.username || '' : undefined}
          wrappedComponentRef={(r: any) => (this._changePasswordFormRef = r)}
          onSubmit={this.submitPasswordChange}
        />
        <br />
        {this.returnToLogin()}
      </React.Fragment>
    );
  }

  renderFacilitySelect = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <PickFacility
          facilities={this.state.facilities}
          onSelect={f => {
            if (f?.length > 0) {
              this.selectFacility(f);
            }
          }}
          style={{ textAlign: 'center' }}
        />
        <Button
          onClick={() => this.selectFacility(this.state.selectedFacilityId || '')}
          type="primary"
          disabled={this.state.selectedFacilityId == null}
          style={{ marginLeft: 15, marginTop: 5 }}
        >
          Submit
        </Button>
      </div>
    );
  }

  private submitLogin = () => {
    if (this._loginFormRef) {
      this._loginFormRef.props.form.validateFields(errors => {
        if (!errors) {
          this.setState({ loading: true, error: null });
          
          let formObj = this._loginFormRef.props.form.getFieldsValue();
          let loginObj = LoginDTO.create({
            userName: formObj.username,
            password: btoa(encodeURIComponent(CLIENT_ID + formObj.password))
          });

          this.saveUserName(formObj.username);

          LoginService.verifyPassword(loginObj)
            .then(result => {
              this.setState({
                loading: false,
                process: result.nextStep,
                loginResponse: result
              });
            })
            .catch(error => {
              this.setState({ loading: false, error: error });
            });
        }
      });
    }
  }

  private submitForgot = () => {
    if (this._forgotFormRef) {
      this._forgotFormRef.props.form.validateFields(errors => {
        if (!errors) {
          let formObj = this._forgotFormRef.props.form.getFieldsValue();
          this.setState({ loading: true, error: null });
          LoginService.forgotPassword(formObj.username).then(_ => {
            this.setState({ loading: false });
            notification.success({
              message: 'A password reset email has been sent.',
              duration: 0
            });
          }).catch(error => {
            this.setState({ loading: false });
            notification.error({
              message: 'Server Did Not Respond',
              description: error.message,
              duration: 0
            });
          });
        }
      });
    }
  }

  private submitDevLogin = () => {
    if (DEBUG && this._devLoginFormRef) {
      this._devLoginFormRef.props.form.validateFields(errors => {
        if (!errors) {
          let formObj = this._devLoginFormRef.props.form.getFieldsValue();
          let dto = LoginDTO.create({ ...formObj });
          this.setState({ loading: true, error: null });
          LoginService.devLogin(dto)
            .then(res => {
              if (res.isRedirected && res.url) {
                window.location.href = res.url;
              }
            })
            .catch(error => {
              this.setState({ loading: false });
              notification.error({
                message: error.message,
                description: error.description
              });
            });
        }
      });
    }
  }

  private submitMfaChallenge = () => {
    if (this._mfaChallengeFormRef) {
      this._mfaChallengeFormRef.props.form.validateFields(errors => {
        if (!errors && this.state.loginResponse) {
          let formObj = this._mfaChallengeFormRef.props.form.getFieldsValue();
          let dto = LoginDTO.create({
            sessionData: this.state.loginResponse.sessionData,
            authCode: formObj.mfaChallenge
          });
          this.setState({ loading: true, error: null });
          LoginService.mfaChallenge(dto)
            .then(result => {

              if (result.facilities && result.facilities.length === 1) {
                this.setState({ loginResponse: result }, () => {
                  this.selectFacility(result.facilities![0].id || '');
                });

              } else {
                this.setState({
                  loading: false,
                  error: null,
                  loginResponse: result,
                  facilities: result.facilities || [],
                  process: LoginStep.SELECT_FACILITY
                });
              }
            })
            .catch(error => {
              this.setState({ loading: false, error: error });
            });
        }
      });
    }
  }

  private submitPasswordChange = () => {
    if (this._changePasswordFormRef) {
      this._changePasswordFormRef.props.form.validateFields(errors => {
        if (!errors) {
          this.setState({ loading: true, error: null });

          let formObj = this._changePasswordFormRef.props.form.getFieldsValue();
          let loginObj = LoginDTO.create({
            username: this.state.username,
            sessionData: this.state.loginResponse ? this.state.loginResponse.sessionData : null,
            resetPasswordKey: this.state.key,
            newPassword: btoa(encodeURIComponent(CLIENT_ID + formObj.password)),
            newPasswordConfirm: btoa(encodeURIComponent(CLIENT_ID + formObj.passwordConfirm))
          });

          LoginService.changePassword(loginObj)
            .then(result => {
              this.setState({ loading: false, process: result.nextStep });
            })
            .catch(error => {
              this.setState({ loading: false, error: error });
            });
        }
      });
    }
  }

  private aboutToExpireResponse = (change: boolean) => {
    if (this.state.loginResponse) {
      let loginObj = LoginDTO.create({
        sessionData: this.state.loginResponse.sessionData
      });
      this.setState({ loading: true, error: null });
      LoginService.aboutToExpireResponse(loginObj, change)
        .then(result => {
          this.setState({ loading: false, process: result.nextStep });

          // HACK: Running into issues with setting the correct session key in all cases. Only explicitly setting 
          //       the loginResponse for this as it was the only path that was broken before. 
          if (result.nextStep === LoginStep.SETUP_MFA) {
            this.setState({ loginResponse: result });
          }
        })
        .catch(error => {
          this.setState({ loading: false, error: error });
        });
    } else {
      this.setState({ process: LoginStep.LOGIN });
    }
  }

  private returnToLogin = () => {
    return (
      <Button
        type="link"
        onClick={() => this.setState({ process: LoginStep.LOGIN })}
      >
        Return to login
      </Button>
    );
  }

  private saveUserName = (username: string | null) => {
    if (username) {
      localStorage.setItem(LAST_LOGIN_USER_KEY, username);
    } else {
      localStorage.removeItem(LAST_LOGIN_USER_KEY);
    }
  }

  private selectFacility(facilityId: string) {
    if (this.state.loginResponse) {
      this.setState({ loading: true, error: null });
      LoginService.selectFacility(facilityId)
        .then(res => {
          if (res.isRedirected && res.url) {
            window.location.href = res.url;
          }
        })
        .catch(err => {
          this.setState({ error: err, loading: false });
        });
    }

  }
}

export default withRouter(Login);
