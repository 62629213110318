import * as React from 'react';
import { Button, Form } from 'antd';
import IpopInput from 'src/shared/form-controls/IpopInput';
import { FormComponentProps, FormCreateOption } from 'antd/lib/form';

const FormItem = Form.Item;

interface LoginFormProps extends FormComponentProps {
  userName: string | null;
  onSubmit: () => void;
  onChange?: (fields: any) => void;
}

interface LoginFormState { }

const formCreateOptions: FormCreateOption<LoginFormProps> = {
  onFieldsChange(props: LoginFormProps, fields: any, allFields: any) {
    if (props.onChange) {
      props.onChange(fields);
    }
  }
};

class LoginForm extends React.Component<LoginFormProps & FormComponentProps, LoginFormState> {
  constructor(props: LoginFormProps & FormComponentProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      isFieldTouched,
      getFieldError
    } = this.props.form;

    // Only show error after a field is touched.
    const usernameError =
      isFieldTouched('username') && getFieldError('username');
    const passwordError =
      isFieldTouched('password') && getFieldError('password');

    return (
      <Form
        className="compact"
        onSubmit={e => {
          e.preventDefault();
          this.props.onSubmit();
        }}
      >
         <FormItem
          id="username"
          labelCol={{ xs: { span: 24 }, sm: { span: 5 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 19 } }}
          label="Email"
          validateStatus={usernameError ? 'error' : ''}
          help={usernameError || ''}
        >
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Required.' }],
            initialValue: this.props.userName
          })(
            <IpopInput autoFocus={!this.props.userName} />
          )}
        </FormItem>
        <FormItem
          id="password"
          labelCol={{ xs: { span: 24 }, sm: { span: 5 } }}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 19 } }}
          label="Password"
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError || ''}
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Required.' }]
          })(
            <IpopInput type="password" autoFocus={!!this.props.userName} />
          )}
        </FormItem>
        <FormItem
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 19, offset: 5 }
          }}
        >
          <Button
            htmlType="submit"
            disabled={this.hasErrors(getFieldsError())}
            type="primary"
          >
            Sign In
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create<LoginFormProps>(formCreateOptions)(LoginForm);
