import * as React from 'react';
import AddDeviceForm from './AddDeviceForm';
import AddMfaDeviceDTO from 'src/models/AddMfaDeviceDTO';
import AuthenticationAppDTO from 'src/models/AuthenticationAppDTO';
import LoginService from '../../api/LoginApiService';
import MfaChallengeForm from '../MfaChallengeForm';
import Title from 'antd/lib/typography/Title';
import { Button, notification, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import LoginDTO from 'src/models/LoginDTO';
import LoginResponseDTO from 'src/models/LoginResponseDTO';

interface MfaSetupProps {
    onCancel: () => void;
    onAdded: (result: LoginResponseDTO) => void;
    cancelText: string;
    authApps: AuthenticationAppDTO[];
    qrUri: string;
    deviceKey: string;
    sessionData: string;
}

interface MfaSetupState {
    loading: boolean;
    showChallenge: boolean;
}

class MfaSetup extends React.Component<MfaSetupProps, MfaSetupState> {

    private _addDeviceFormRef: React.Component<FormComponentProps>;
    private _challengeFormRef: React.Component<FormComponentProps>;

    constructor(props: MfaSetupProps) {
        super(props);

        this.state = {
            loading: false,
            showChallenge: false
        };
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div style={{
                    display: this.state.showChallenge ? 'none' : 'block'
                }}>
                    <Title level={3}>Add Device</Title>
                    <AddDeviceForm
                        wrappedComponentRef={(r: any) => this._addDeviceFormRef = r}
                        authApps={this.props.authApps}
                        qrUri={this.props.qrUri}
                        deviceKey={this.props.deviceKey}
                        onSubmit={() => this.addDevice}
                    />

                    <Button type="primary" onClick={this.addDevice}>Add Device</Button>
                    <Button type="link" onClick={this.props.onCancel}>{this.props.cancelText}</Button>
                </div>
                <div style={{
                    display: this.state.showChallenge ? 'block' : 'none'
                }}>
                    <React.Fragment>
                        <Title level={3}>Verify Device</Title>
                        <MfaChallengeForm
                            wrappedComponentRef={(r: any) => this._challengeFormRef = r}
                            onSubmit={this.verifyDevice}
                            submitText="Verify Device"
                        />
                        <Button type="link" onClick={() => this.setState({ showChallenge: false })}>Cancel</Button>
                    </React.Fragment>
                </div>
            </Spin>
        );
    }

    private addDevice = () => {
        if (this._addDeviceFormRef) {
            this._addDeviceFormRef.props.form.validateFields(errors => {
                if (!errors) {
                    this.setState({ showChallenge: true });
                }
            });
        }
    }

    private verifyDevice = () => {
        if (this._challengeFormRef) {
            this._challengeFormRef.props.form.validateFields(errors => {
                if (!errors) {
                    let formObj = this._addDeviceFormRef.props.form.getFieldsValue();
                    let challengeFormObj = this._challengeFormRef.props.form.getFieldsValue();
                    let dto =
                        LoginDTO.create({
                            sessionData: this.props.sessionData,
                            addMfaDevice: AddMfaDeviceDTO.create({ ...formObj }),
                            authCode: challengeFormObj.mfaChallenge
                        });

                    this.setState({ loading: true });
                    LoginService.verifyNewMfaDevice(dto).then(result => {
                        this.props.onAdded(result);
                    }).catch(error => {
                        this.setState({ loading: false });
                        notification.error({
                            message: 'Failed to add device',
                            description: error.message
                        });
                    });
                }
            });
        }
    }
}

export default MfaSetup;
