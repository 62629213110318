enum LoginStep {
    LOGIN = 0,
    FORGOT = 1,
    CHANGE_PASSWORD = 2,
    ABOUT_TO_EXPIRE = 3,
    CHALLENGE = 4,
    SETUP_MFA = 5,
    SELECT_FACILITY = 6,
    DONE = 7,
}
export default LoginStep;
