// @ts-ignore
import * as moment from 'moment';
import InterfaceConstructor from './InterfaceConstructor';
import AddMfaDeviceDTO from './AddMfaDeviceDTO';

interface LoginDTO { 
    sessionData: string | null;
    username: string | null;
    password: string | null;
    newPassword: string | null;
    newPasswordConfirm: string | null;
    resetPasswordKey: string | null;
    authCode: string | null;
    addMfaDevice: AddMfaDeviceDTO | null;
    facilityId: string | null;
}
const LoginDTO: InterfaceConstructor<LoginDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            sessionData: null,
            username: null,
            password: null,
            newPassword: null,
            newPasswordConfirm: null,
            resetPasswordKey: null,
            authCode: null,
            addMfaDevice: null,
            facilityId: '00000000-0000-0000-0000-000000000000',
        },
        initValues);
    }
};

export default LoginDTO;
