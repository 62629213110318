// @ts-ignore
import * as moment from 'moment';
import InterfaceConstructor from './InterfaceConstructor';

interface AddMfaDeviceDTO { 
    deviceName: string | null;
    authAppCode: string | null;
    authAppName: string | null;
    valid: boolean;
    code: string | null;
}
const AddMfaDeviceDTO: InterfaceConstructor<AddMfaDeviceDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            deviceName: null,
            authAppCode: null,
            authAppName: null,
            valid: false,
            code: null,
        },
        initValues);
    }
};

export default AddMfaDeviceDTO;
