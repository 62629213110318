import * as React from 'react';
import Title from 'antd/lib/typography/Title';
import { Form, notification, Spin, Button } from 'antd';
import { FormComponentProps, FormCreateOption } from 'antd/lib/form';
import LoginService from 'src/api/LoginApiService';
import DeveloperLoginDTO from 'src/models/DeveloperLoginDTO';
import IpopSelect from 'src/shared/form-controls/IpopSelect';

const DevLoginStorageKey = '_DevLogin';

const FormItem = Form.Item;

interface DevLoginFormProps extends FormComponentProps {
  onSubmit: () => void;
}

interface DevLoginFormState {
  loading: boolean;
  devLogin: DeveloperLoginDTO | null;
  facilityId: string | null;
  userId: string | null;
}

const formCreateOptions: FormCreateOption<DevLoginFormProps> = {
  onFieldsChange(props: DevLoginFormProps, fields: any[], allFields: any) {
    // pass
  }
};

class DevLoginForm extends React.Component<DevLoginFormProps & FormComponentProps, DevLoginFormState> {
  constructor(props: DevLoginFormProps & FormComponentProps) {
    super(props);

    let lastLoginJson = localStorage.getItem(DevLoginStorageKey);
    let lastLogin: any = {};
    if (lastLoginJson) {
      lastLogin = JSON.parse(lastLoginJson);
    }

    this.state = {
      loading: false,
      devLogin: null,
      facilityId: lastLogin ? lastLogin.facility : null,
      userId: lastLogin ? lastLogin.user : null,
    };
  }

  componentDidMount() {
    if (DEBUG) {
      this.getDevLogin(() => {
        if (this.state.facilityId) {
          this.facilitySelected(this.state.facilityId);
        }
      });
    }
  }

  render() {
    if (!DEBUG) {
      return null;
    }

    if (!this.state.devLogin) {
      return null;
    }

    const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.loading}>
        <Title level={3} className="center-content">Developer Login</Title>
        <br />
        <Form layout="horizontal" className="form-flex label-100 compact">
          <FormItem
            label="Facility"
          >
            {getFieldDecorator('facilityId', {
              initialValue: this.state.facilityId || undefined,
              rules: [{ required: true, message: 'Facility required.' }]
            })(
              <IpopSelect
                onChange={this.facilitySelected}
                placeholder="Select Facility"
                options={(this.state.devLogin.facilities || []).map(f => ({ value: f.id || '', label: f.name || '' }))}
              />
            )}
          </FormItem>

          <FormItem
            label="User"
          >
            {getFieldDecorator('userName', {
              initialValue: this.state.userId || undefined,
              rules: [{ required: true, message: 'User required.' }]
            })(
              <IpopSelect
                placeholder="Select User"
                options={(this.state.devLogin.users || []).map(u => ({ value: u.userName || '', label: u.firstName || '' }))}
              />
            )}
          </FormItem>
          <FormItem colon={false} label="&nbsp;">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                let vals = this.props.form.getFieldsValue();
                localStorage.setItem(DevLoginStorageKey, JSON.stringify({ facility: vals.facilityId, user: vals.userName }));
                this.props.onSubmit();
              }}
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin >
    );
  }

  private getDevLogin = (cb?: () => void) => {
    if (DEBUG) {
      this.setState({ loading: true });
      LoginService.getDeveloperLogin()
        .then(res => {
          this.setState({ devLogin: res, loading: false }, cb);
        })
        .catch(error => {
          notification.error({
            message: error.message,
            description: error.description
          });
        });
    }
  }

  private facilitySelected = (e: any) => {
    if (this.state.devLogin) {
      this.setState({ loading: true });
      let dto = this.state.devLogin;
      dto.facilityId = e;
      LoginService.updateDeveloperLogin(dto)
        .then(res => {
          this.setState({ devLogin: res, loading: false });
        })
        .catch(error => {
          notification.error({
            message: error.message,
            description: error.description
          });
        });
    }
  }
}

export default Form.create<DevLoginFormProps>(formCreateOptions)(DevLoginForm);
