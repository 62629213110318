import * as React from 'react';
import FacilityLookupDTO from 'src/models/FacilityLookupDTO';
import Title from 'antd/lib/typography/Title';
import IpopSelect from 'src/shared/form-controls/IpopSelect';

interface PickFacilityProps {
    facilities: FacilityLookupDTO[];
    onSelect: (facilityId: string) => void;
    style?: React.CSSProperties;
}

interface PickFacilityState {
    selectedFacility: string | null;
}

class PickFacility extends React.Component<PickFacilityProps, PickFacilityState> {
    constructor(props: PickFacilityProps) {
        super(props);

        this.state = {
            selectedFacility: null
        };
    }

    componentDidMount() {
        // Fetch facilities
    }

    render() {
        return (
            <div style={this.props.style}>
                <Title level={3} className="center-content">Select Facility</Title>
                <IpopSelect
                    id="facilities"
                    style={{ width: 300 }}
                    onSelect={this.onSelect}
                    value={this.state.selectedFacility}
                    autoFocus={true}
                    options={(this.props.facilities || []).map(f => ({ value: f.id || '', label: f.name || '' }))}
                />
            </div>
        );
    }

    private onSelect = (e: string) => {
        this.setState({ selectedFacility: e || null });
        this.props.onSelect(e);
    }
}

export default PickFacility;
