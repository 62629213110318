
import BaseApi from './BaseApi';
import LoginDTO from '../models/LoginDTO';
import DeveloperLoginDTO from '../models/DeveloperLoginDTO';
import LoginResponseDTO from '../models/LoginResponseDTO';
import GenericResponseDTO from '../models/GenericResponseDTO';

export class LoginApiService extends BaseApi {

    // post: api/login/VerifyPassword
    public verifyPassword(dto: LoginDTO): Promise<LoginResponseDTO> {
        let url = `api/login/VerifyPassword`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResponseDTO>(dto, 'post', url, true, false);
    }

    // get: api/login/GetDeveloperLogin
    public getDeveloperLogin(): Promise<DeveloperLoginDTO> {
        let url = `api/login/GetDeveloperLogin`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeveloperLoginDTO>(null, 'get', url, true, false);
    }

    // post: api/login/UpdateDeveloperLogin
    public updateDeveloperLogin(dto: DeveloperLoginDTO): Promise<DeveloperLoginDTO> {
        let url = `api/login/UpdateDeveloperLogin`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, DeveloperLoginDTO>(dto, 'post', url, true, false);
    }

    // post: api/login/DevLogin
    public devLogin(dto: LoginDTO): Promise<GenericResponseDTO> {
        let url = `api/login/DevLogin`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GenericResponseDTO>(dto, 'post', url, true, false);
    }

    // post: api/login/Forgot?username=${encodeURIComponent(username)}
    public forgotPassword(username: string): Promise<void> {
        let url = `api/login/Forgot`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (username != null) {
            url += `${prefix}username=${encodeURIComponent(username)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, void>(null, 'post', url, true, false);
    }

    // post: api/login/ChangePassword
    public changePassword(dto: LoginDTO): Promise<LoginResponseDTO> {
        let url = `api/login/ChangePassword`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResponseDTO>(dto, 'post', url, true, false);
    }

    // post: api/login/AboutToExpireResponse?change=${change}
    public aboutToExpireResponse(dto: LoginDTO, change: boolean): Promise<LoginResponseDTO> {
        let url = `api/login/AboutToExpireResponse`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (change != null) {
            url += `${prefix}change=${change}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResponseDTO>(dto, 'post', url, true, false);
    }

    // post: api/login/VerifyNewMfaDevice
    public verifyNewMfaDevice(dto: LoginDTO): Promise<LoginResponseDTO> {
        let url = `api/login/VerifyNewMfaDevice`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResponseDTO>(dto, 'post', url, true, false);
    }

    // post: api/login/MfaChallenge
    public mfaChallenge(dto: LoginDTO): Promise<LoginResponseDTO> {
        let url = `api/login/MfaChallenge`;

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, LoginResponseDTO>(dto, 'post', url, true, false);
    }

    // post: api/login/SelectFacility?facilityId=${encodeURIComponent(facilityId)}
    public selectFacility(facilityId: string): Promise<GenericResponseDTO> {
        let url = `api/login/SelectFacility`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (facilityId != null) {
            url += `${prefix}facilityId=${encodeURIComponent(facilityId)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        return this.SendRequest<any, GenericResponseDTO>(null, 'post', url, true, false);
    }
}
var service = new LoginApiService();
export default service;
