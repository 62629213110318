import * as React from 'react';
import { Form, Button } from 'antd';
import IpopInput from 'src/shared/form-controls/IpopInput';
import { FormComponentProps, FormCreateOption } from 'antd/lib/form';

const FormItem = Form.Item;

interface ChangePasswordFormProps extends FormComponentProps {
    username?: string;
    onSubmit: () => void;
}

interface LoginFormState {

}

const formCreateOptions: FormCreateOption<ChangePasswordFormProps> = {
    onFieldsChange(props: ChangePasswordFormProps, fields: any[], allFields: any) {
        // pass
    }
};

class ChangePasswordForm extends React.Component<ChangePasswordFormProps & FormComponentProps, LoginFormState> {
    constructor(props: ChangePasswordFormProps & FormComponentProps) {
        super(props);

        this.state = {

        };
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
            <Form layout="horizontal" className="form-flex label-160">
                {this.props.username ? (
                    <FormItem label="Username">
                        <span>{this.props.username}</span>
                    </FormItem>
                ) : null}
                <FormItem label="New Password">
                    {getFieldDecorator('password', {
                        rules: [
                            { required: true, message: 'Required.' },
                        ]
                    })(<IpopInput id="passwordId" type="password" />)}
                </FormItem>

                <FormItem label="Confirm New Password">
                    {getFieldDecorator('passwordConfirm', {
                        rules: [
                            { required: true, message: 'Required.' },
                            {
                                validator: (rules, value, callback) => {
                                    if (value && value !== getFieldValue('password')) {
                                        callback('Does not match.');
                                    } else {
                                        callback();
                                    }
                                }
                            }]
                    })(<IpopInput type="password" />)}
                </FormItem>
                <FormItem label="&nbsp;" colon={false}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.props.onSubmit}
                    >
                        Submit
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create<ChangePasswordFormProps>(formCreateOptions)(ChangePasswordForm);